module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kitchenfy","short_name":"Kitchenfy","description":"Cozinhas inteligentes para o seu delivery. Espaços com infraestrutura completa e moderna nos melhores bairros.","start_url":"/","background_color":"#E52530","theme_color":"#E52530","display":"minimal-ui","icon":"src/images/og/og-small.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0c69d76f4768722cdbd8f0d7599f0a82"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-176139119-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"GTM-WT3JVPG","cookieName":"gatsby-gdpr-google-tagmanager","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
